
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import State from "@/components/reusable/State.vue";
import Gender from "@/components/reusable/Gender.vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import Address from "@/components/reusable/Address.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "cooperative-edit-member",
  components: {
    ErrorMessage,
    Field,
    Form,
    State,
    Gender,
    Address,
  },
  props: ["publicId"],
  data() {
    return {
      displayDates: false,
      newMemberDetails: {
        alternativePhoneNumber: "",
        avatar: "",
        dateOfBirth: "",
        emailAddress: "",
        firstName: "",
        gender: "",
        address: "",
        identificationExpiryDate: "",
        identificationIssueDate: "",
        identificationNumber: "",
        lastName: "",
        localGovernmentArea: "",
        maritalStatus: "",
        meansOfIdentification: "",
        identificationImage: "",
        middleName: "",
        phoneNumber: "",
        state: "",
        ward: "",
        bvn: "",
      },
    };
  },
  setup() {
    const router = useRouter();
    const submitButton1 = ref<HTMLElement | null>(null);

    const newMemberDetailsValidator = Yup.object().shape({
      firstName: Yup.string()
        .required()
        .label("First name"),
      middleName: Yup.string()
        .notRequired()
        .label("First name"),
      lastName: Yup.string()
        .required()
        .label("Last name"),
      emailAddress: Yup.string()
        .required()
        .label("Email address"),
      phoneNumber: Yup.number()
        .required()
        .label("Phone number"),
      alternativePhoneNumber: Yup.string()
        .notRequired()
        .label("Alternative phone number"),
      dateOfBirth: Yup.date()
        .required()
        .label("Date of birth"),
      gender: Yup.mixed()
        .required()
        .label("Gender"),
      maritalStatus: Yup.mixed()
        .required()
        .label("Martial status"),
      state: Yup.mixed()
        .required()
        .label("State"),
      localGovernmentArea: Yup.mixed()
        .required()
        .label("Local government area"),
      meansOfIdentification: Yup.mixed()
        .notRequired()
        .label("Means of identification"),
      identificationNumber: Yup.string()
        .required()
        .label("Identification number"),
      identificationImage: Yup.mixed()
        .required()
        .label("Identification image"),
      identificationIssueDate: Yup.date()
        .notRequired()
        .label("Date of issue"),
      identificationExpiryDate: Yup.date()
        .notRequired()
        .label("Date of expiry"),
      address: Yup.string()
        .required()
        .label("Home address"),
    });

    const createMember = (values, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(variables.COOPERATIVE_MEMBERS_ROUTE, values)
              .then((response) => {
                console.log(response);
                variables.toastAlert(response.data.data.message, "success");
                resetForm();
                router.push({ name: "cooperative-admin-members-list" });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Edit Member", ["Cooperative", "Members"]);
    });

    return {
      submitButton1,
      createMember,
      newMemberDetailsValidator,
    };
  },
  created() {
    this.getMemberInformation();

    //Set page title
    document.title = "Cooperative: Edit Member | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getMemberInformation() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${variables.COOPERATIVE_MEMBERS_DETAILS_ROUTE}${this.publicId}`
        )
          .then((response) => {
            //Assign data to variables
            this.newMemberDetails = response.data.data.personalInformation;
            const profileAvatar = response.data.data.personalInformation.avatar;

            if (profileAvatar != "") {
              this.newMemberDetails.avatar =
                variables.AVATAR_ROUTE + profileAvatar;
            } else {
              this.newMemberDetails.avatar = "/media/avatars/blank.png";
            }

            if (
              this.newMemberDetails.meansOfIdentification ==
                "Drivers License" ||
              this.newMemberDetails.meansOfIdentification ==
                "International Passport"
            ) {
              this.displayDates = true;
            }
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    removeImage() {
      this.newMemberDetails.avatar = "../media/avatars/blank.png";
    },
    acceptNumberOnly(event) {
      variables.acceptNumberOnly(event);
    },
    handleImageUpload(event, modelName) {
      const selectedImage = event.target.files[0]; //get the first file
      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);

      reader.onload = (e) => {
        if (modelName == "avatar") {
          this.newMemberDetails.avatar = reader.result as string;
        } else {
          this.newMemberDetails.identificationImage = reader.result as string;
        }
      };

      reader.onerror = function(error) {
        variables.toastAlert(error, "error");
      };
    },
    identificationIssueDateAndExpiry(event) {
      const value = event.target.value;

      value == "Drivers License" || value == "International Passport"
        ? (this.displayDates = true)
        : (this.displayDates = false);
      const dateIssued = document.getElementById(
        "date-of-issue"
      ) as HTMLInputElement;
      const dateExpired = document.getElementById(
        "date-of-expiry"
      ) as HTMLInputElement;

      dateIssued.value != "" ? dateIssued.value : "";
      dateExpired.value != "" ? dateExpired.value : "";
    },
    generatePassword() {
      const passwordField = document.getElementById(
        "password"
      ) as HTMLInputElement;
      const confirmPasswordField = document.getElementById(
        "confirm-password"
      ) as HTMLInputElement;

      const generatedPassword = variables.generatePassword();
      passwordField.value = generatedPassword;
      confirmPasswordField.value = generatedPassword;
    },
  },
});
